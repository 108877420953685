<script>
import { Doughnut } from 'vue-chartjs'
import { PieChartOptions } from './chartOptions'
import CustomChartMethods from './customChartMethods'
import { useAccessibility } from '../../composables/useAccessibility'
export default {
  name: 'DoughnutChart',
  setup() {
    const { applyChartAccessibility } = useAccessibility()
    return { applyChartAccessibility }
  },
  extends: Doughnut,
  props: ['chartData', 'colorsList'],
  data() {
    return {
      options: PieChartOptions,
    }
  },
  mounted() {
    this.plotChart()
  },
  methods: {
    destroyChart() {
      if (this.$data._chart) {
        this.$data._chart.destroy()
      }
    },
    plotChart() {
      this.destroyChart()
      const chartData = this.chartData ? new CustomChartMethods().getChartData('DoughnutChart', this.colorsList, this.chartData) : null
      this.renderChart(chartData, this.options)
      this.applyChartAccessibility()
    },
  },
  destroyed() {
    this.destroyChart()
  },
  watch: {
    chartData(newValue, oldValue) {
      this.plotChart()
    },
  },
}
</script>
